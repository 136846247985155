import * as React from "react";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import Logo from "../images/logos/digital-trail.svg";
import QR from "../images/logos/qr.svg";
import WCC from "../images/logos/WCC.svg";
import AncientEast from "../images/logos/ancient-east.svg";
import WhereIreland from "../images/logos/where-ireland.svg";
import USI from "../images/logos/USI.svg";
import Imvizar from "../images/logos/imvizar.svg";
import { Helmet } from "react-helmet";

// markup
const Index = () => {
  
  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  return (
    <Layout>

    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail</title>
      <meta name="description" content="A digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </Helmet>

      <main>
        <title>Waterford AR Trail</title>

        <div className="location-hero h-full w-screen hidden lg:block">
          <div className="center-screen">
          <div className="grid grid-cols-2 gap-12 py-24 w-4/6 mx-auto">
            <div className="mx-auto">
            <img loading="lazy"
              className="mx-auto py-16"
              src={Logo}
              alt="Waterford Digital Trail"
            />
            <h2 className=" font-lower text-center text-3xl text-waterford-gold">The digital story trail is made to be viewed on mobile or tablet devices.</h2>
            </div>
            <div className="mx-auto">
            <img loading="lazy"
              className="mx-auto py-16"
              src={QR}
              alt="Waterford Digital Trail"
            />
            </div>
          </div>

      <div className="grid grid-cols-5 border-t-2 border-waterford-gold mx-auto w-5/6">
        <img className="mx-auto w-full p-4 h-16" loading="lazy" alt="Ireland's Ancient East" src={AncientEast}></img>
        <img className="mx-auto w-full p-4 h-16" loading="lazy" alt="Visit Waterford" src={WhereIreland}></img>
        <img className="mx-auto w-full p-4 h-16" loading="lazy" src={WCC} alt="Waterford City Council"></img>
        <img className="mx-auto w-full p-4 my-auto h-16" loading="lazy" alt="Urban Scale Interventions" src={USI}></img>
        <img className="mx-auto w-full p-4 h-16" loading="lazy" src={Imvizar} alt="Imvizar"></img>
      </div>

      
      </div>
      </div>


        <div className="hero lg:hidden">
        <section className="w-full h-full font-lower">
          <div className="container mx-auto w-6/6 lg:w-5/6 py-24">
            <img
              className="mx-auto py-16"
              src={Logo}
              alt="Waterford Digital Trail"
            />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-36 md:gap-2 lg:gap-4 py-16 text-center">
              <Link to="/reginaldstower/">
                <div id={t("reginald")} className="stopAR rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-4 md:ml-auto 
                  transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 rounded-full" 
                   data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                </div>
              </Link>

              <Link to="/greyfriars">
                  <div id={t("wadding")} className="stopAudio rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto mr-6 md:mr-auto 
                  transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 rounded-full"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/coffeehouselane">
                  <div id={t("aikenhead")} className="rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-6 md:ml-auto transition duration-500 
                  ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 rounded-full" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-32 md:gap-2  lg:gap-4 py-16 text-center">
              <Link to="/cathedralsquare">
                  <div id={t("condon")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 rounded-full 
                  h-40 w-40 hover:bg-opacity-100 mx-auto mr-6 md:mr-auto"   
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/aoifestrongbow">
                  <div id={t("aoife")} className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none 
                  p-28 rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto mr-36 md:mr-auto"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/waterfordcrystal">
                  <div id={t("penrose")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-6 md:ml-auto" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">

                  </div>
              </Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-36 md:gap-2 lg:gap-4 py-16 text-center">
              <Link to="/watchtower">
                  <div id={t("cannon")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto mr-20 md:mr-auto" data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/applemarket">
                  <div id={t("apple")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto mr-6 md:mr-auto"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/arundelsquare">
                  <div id={t("fleming")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none 
                  p-28 rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-16 md:ml-auto" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-36 md:gap-2 lg:gap-4 py-16 text-center">
              <Link to="/blackfriars">
                  <div id={t("geoffrey")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-6 md:ml-auto" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/mostholytrinity">
                  <div id={t("roberts")} className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-6 md:ml-auto"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/clocktower">
                  <div id={t("maddock")} className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-16 md:ml-auto" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-36 md:gap-2 lg:gap-4 py-16 text-center">
              <Link to="/granville">
                  <div id={t("meagher")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto mr-6 md:mr-auto"
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">

                  </div>
              </Link>

              <Link to="/chambercommerce">
                  <div id={t("carew")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28 
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto mr-20 md:mr-auto" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>

              <Link to="/beachtower">
                  <div id={t("beachtower")} className=" transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-28
                  rounded-full h-40 w-40 hover:bg-opacity-100 mx-auto ml-3 md:ml-auto" 
                  data-sal="slide-down"
                  data-sal-delay="300"
                  data-sal-easing="ease">
                  </div>
              </Link>
            </div>
          </div>
        </section>
        </div>
      </main>
    </Layout>
  );
};
export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
